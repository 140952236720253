import React from "react"
import { Link } from "gatsby"
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from "swiper"
//import Image from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade])

const HomeOurTeam = ({ ourTeamImg1, ourTeamImg2 }) => {
  return (
    <section className="our-team">
      <div className="container">
        <div className="our-team--left">
          <Swiper
            tag="section"
            effect={"fade"}
            slidesPerView={1}
            spaceBetween={30}
            slidesPerGroup={1}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            navigation={false}
            className="ourTeamSwiper"
            onClick={(swiper, e) => {
              swiper.slideNext()
            }}
          >
            <SwiperSlide>
              <GatsbyImage
                image={ourTeamImg1.childImageSharp.gatsbyImageData}
                alt="Diagrams and maps about climate change risk analysis"
              />
            </SwiperSlide>
            <SwiperSlide>
              <GatsbyImage
                image={ourTeamImg2.childImageSharp.gatsbyImageData}
                alt="Diagrams and maps about climate change risk analysis"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="our-team--right">
          <h2>Experts in science and real estate</h2>
          <p>
            We are science, real estate, and technology veterans with a passion
            for helping people and businesses become more resilient to the
            changing climate.
          </p>
          <Link to="/about-us#our-team" className="link-btn">
            About the Team
            <CTARightArrow />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeOurTeam
